<template>
  <div id="app">
    <MyHeaderVue />
    <router-view></router-view>
    <MyFooterVue />
  </div>
</template>

<script>
// import MyHeaderVue from './page/chatchaimobile/MyHeader.vue'
// import MyFooterVue from './page/chatchaimobile/MyFooter.vue'

//jaidee官网
// import MyHeaderVue from './page/jaidee/MyHeader.vue'
// import MyFooterVue from './page/jaidee/MyFooter.vue'

//jaideeconnect
// import MyHeaderVue from './page/jaideeconnect/MyHeader.vue'
// import MyFooterVue from './page/jaideeconnect/MyFooter.vue'



//越南kasikvay
// import MyHeaderVue from './page/kasikvay/MyHeader.vue'
// import MyFooterVue from './page/kasikvay/MyFooter.vue'

//越南tamogiefectivo
// import MyHeaderVue from './page/tamogiefectivo/MyHeader.vue'
// import MyFooterVue from './page/tamogiefectivo/MyFooter.vue'

//墨西哥consedeefectivo
// import MyHeaderVue from './page/consedeefectivo/MyHeader.vue'
// import MyFooterVue from './page/consedeefectivo/MyFooter.vue'

//越南nath
// import MyHeaderVue from './page/components/MyHeader5.vue'
// import MyFooterVue from './page/components/MyFooter3.vue'

//越南mrVaymobile
// import MyHeaderVue from './page/mrVaymobile/MyHeader.vue'
// import MyFooterVue from './page/mrVaymobile/MyFooter.vue'


//越南Hcthlog Plus
// import MyHeaderVue from './page/hcthlogplus/MyHeader.vue'
// import MyFooterVue from './page/hcthlogplus/MyFooter.vue'

//墨西哥hipotecariabilletera
// import MyHeaderVue from './page/hipotecariabilletera/MyHeader.vue'
// import MyFooterVue from './page/hipotecariabilletera/MyFooter.vue'

//越南sunmobile
// import MyHeaderVue from './page/sunmobile/MyHeader.vue'
// import MyFooterVue from './page/sunmobile/MyFooter.vue'

//越南cathaydonge
import MyHeaderVue from './page/cathaydong/MyHeader.vue'
import MyFooterVue from './page/cathaydong/MyFooter.vue'


//越南newvay
// import MyHeaderVue from './page/newvay/MyHeader.vue'
// import MyFooterVue from './page/newvay/MyFooter.vue'

//墨西哥eldefiardinero
// import MyHeaderVue from './page/eldefiardinero/MyHeader.vue'
// import MyFooterVue from './page/eldefiardinero/MyFooter.vue'

//越南thanhchungplus
// import MyHeaderVue from './page/thanhchungplus/MyHeader.vue'
// import MyFooterVue from './page/thanhchungplus/MyFooter.vue'

//墨西哥dealcorpefectivo
// import MyHeaderVue from './page/dealcorpefectivo/MyHeader.vue'
// import MyFooterVue from './page/dealcorpefectivo/MyFooter.vue'

//墨西哥kampalacash
// import MyHeaderVue from './page/kampalacash/MyHeader.vue'
// import MyFooterVue from './page/kampalacash/MyFooter.vue'

export default {
  name: "App",
  components: {
    MyHeaderVue,
    MyFooterVue,
  },
};
</script>

<style>
#app {
  color: #2c3e50;
}
</style>
